body {
    font-size: 1.2rem;
    font-family: 'Nexa Light', sans-serif;
}

.webapp-title {
    font-size: 26px;
    font-weight: bold;
    color: $secondary;
}

.thats-piquest-list {
    list-style-type: disc;
    margin-left: 25px;
}

.highlight-text {
    color: red;
}

.team-name {
    font-size: 20px;
    font-weight: bold;
}

.navbar-item {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
}

.app-image {
    width: 160px;
}

.app-image:hover {
    box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.18) !important;
}

.gif-image {
  padding: 80px;
}

@media only screen and (max-width: 600px) {
  .gif-image {
    padding: 10px;
  }
}

#logo-blue {
    display: none;
}